body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



h1,
h2,
h3 {
  margin: 0.5rem 0;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.2rem;
}
h3 {
  font-size: 1rem;
}
a {
  text-decoration: none;
}
.row {
  display: flex;
  justify-content: space-between;
}
.row.center {
  align-items: center;
}
.col-1 {
  flex: 1;
}
.col-2 {
  flex: 2;
}
.block {
  background-color: #e0e0e0;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}
.text-right {
  text-align: right;
}
img.small {
  max-height: 8rem;
}
button {
  font-size: 0.8rem;
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.5rem;
  border: 0.1rem #404040 solid;
  background-color: #f0c040;
  
  cursor: pointer;
}
button.add {
  background-color: #40c0f0;
  width: 1.5rem;
}
button.remove {
  background-color: #f04040;
  width: 1.5rem;
}
button.badge {
  background-color: #f04040;
  border: none;
  color: #ffffff;
  width: 1.5rem;
}
