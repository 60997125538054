
@media only screen and (min-width: 768px) {

    #slidemain{
        height: 350px;
    }

}    

@media only screen and (max-width: 768px) {
    #slidemain{
        height: 150px;
    }


} 



