#mview{display:block;}
    
#dview{display:block;}

@media only screen and (min-width: 768px) {
    #mview{ display:none;}
        
}    

@media only screen and (max-width: 768px) {
    #dview{ display:none;}
    #mview{
        padding-right: 15px;
        margin-top: -3px;
    }
} 



